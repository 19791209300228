<template>
  <div>
    <input-qr-scan-model
      ref="inputQr_1"
      v-model="code"
      class="mb-5"
      :label="$t('labels.basket_code')"
      @keyupEnter="onCheck"
    ></input-qr-scan-model>

    <div class="my-3">
      <v-row>
        <v-col cols="6">
          <b>{{ $t("labels.basket_code") }}:</b>
          <span v-if="basket && basket.code">{{ basket.code }}</span>
        </v-col>
        <v-col cols="6" class="text-right">
          <b>{{ $t("labels.status") }}:</b>
          <template v-if="basket && basket.code">
            <span
              v-if="basket.current_type == 2 && basket.is_request_find_goods"
            >
              {{ $t("labels.pickup_missing") }}
            </span>
            <span v-else>
              {{ $t(`labels.basket_type_${basket.current_type}`) }}
            </span>
          </template>
        </v-col>
      </v-row>
    </div>
    <div v-if="basket && basket.orders">
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.customer_order_id") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods_name") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods_description") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.size") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 200px">
                {{ $t("labels.position") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.available") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.missing_quantity") }}
              </th>
            </tr>
          </thead>
          <tbody v-for="order in basket.orders" :key="`o_${order.tracking_id}`">
            <tr
              class="text-center tr-h-36px"
              v-for="(item, index) in order.orderItems"
              :key="`${index}_${item.sku}_${order.tracking_id}`"
              :class="{
                'error--text font-weight-medium':
                  item.missing_quantity && item.missing_quantity > 0,
                'border-bottom-thin': !order.note,
              }"
            >
              <td
                v-if="index === 0"
                :rowspan="order.orderItems.length + 1"
                style="vertical-align: top"
                class="font-weight-medium"
              >
                <OrderTracking :tracking-id="order.tracking_id" />
              </td>
              <td style="vertical-align: top">{{ item.sku }}</td>
              <td style="vertical-align: top">{{ item.name }}</td>
              <td style="vertical-align: top">{{ item.description }}</td>
              <td style="vertical-align: top">{{ item.size }}</td>
              <td style="vertical-align: top">{{ item.storage_location }}</td>
              <td style="vertical-align: top">{{ item.available }}</td>
              <td style="vertical-align: top">{{ item.quantity }}</td>
              <td style="vertical-align: top">
                {{ item.missing_quantity || "" }}
              </td>
            </tr>
            <tr v-if="order.note" class="tr-h-36px">
              <td
                colspan="7"
                class="border-bottom-thin font-italic"
                :class="order.is_missing ? 'error--text' : 'primary--text'"
              >
                {{ $t("labels.note") }}: {{ order.note }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-for="type in basketTypes" :key="`at_${type}`">
      <audio :id="`basket_type_${type}`">
        <source
          type="audio/mpeg"
          :src="require(`@/assets/audio/${audioPrefix}basket_type_${type}.wav`)"
        />
      </audio>
    </div>
    <audio id="basket_type_pickup_find_goods">
      <source
        type="audio/mpeg"
        :src="require(`@/assets/audio/basket_type_pickup_find_goods.wav`)"
      />
    </audio>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "CheckBasket",
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  data: () => ({
    code: null,
    basketTypes: [1, 2, 3, 4, 5, 11, 12, 13, 14, 15, 16, 17],
    basket: {},
    audioPrefix: "",
  }),
  created() {
    const lang = localStorage.getItem("_lang") || "vi";
    if (lang == "en") {
      this.audioPrefix = "en_";
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.inputQr_1.focusInput();
    }, 500);
  },
  methods: {
    async onCheck() {
      try {
        const { data } = await httpClient.post("/common/v1/check-basket", {
          code: this.code,
        });
        this.basket = { ...data };
        this.code = null;
        if (!data) {
          document.getElementById("error_sound_player").play();
        }
        if (!data.current_type) {
          document.getElementById("success_sound_player").play();
        } else {
          if (data.current_type == 2 && data.is_request_find_goods) {
            document.getElementById(`basket_type_pickup_find_goods`).play();
            this.$vToastify.error(this.$t("messages.report_missing_basket"));
          } else {
            document.getElementById(`basket_type_${data.current_type}`).play();
            this.$vToastify.error(
              this.$t(`labels.basket_type_${data.current_type}`)
            );
          }
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
